import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import Basket from 'components/Basket';
import Subheader from 'components/Subheader';
import Statistic from 'components/Statistic';
import { History } from 'features/History';
import GameWindow from './GameWindow';
import DotsAnimation from './DotsAnimation';
import Pause from './Pause';

import './Game.scss';

const b = block('game');

const Game = () => {
  const [isPause, setPause] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const periodType = useSelector(state => state.game.periodType);

  useEffect(() => {
    if (periodType === 2) {
      setPause(false)
      if (!timeoutId) {
        setTimeoutId(setTimeout(() => setPause(true), 2250));
      }
    }
    if (periodType === 1) {
      setPause(false)
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null)
      }
    }
  }, [periodType, timeoutId])

  return <article className={b()}>
    {window.innerWidth > 990 ? <React.Fragment>
      <div className={b('left-header-layout')}>
        <Subheader />
      </div>
      <div className={b('content')}>
        <div className={b('history-layout')}>
          <History />
        </div>
        <div className={b('game-components')}>
          <div className={b('statistic-layout')}>
            <Statistic />
          </div>
          <div className={b('game-layout')}>
            {(isPause && periodType === 2)
              ? <Pause />
              : <>
                <div className={b('vertical-animation')}>
                  <DotsAnimation isVertical={true} />
                  <div className={b('game-window-container')}>
                    <GameWindow />
                  </div>
                </div>
                <DotsAnimation isVertical={false} />
              </>
            }
          </div>
          <div className={b('basket-layout')}>
            <Basket />
          </div>
        </div>
      </div>
    </React.Fragment>
      : window.innerWidth > 640 ? <React.Fragment>
        <div className={b('left-header-layout')}>
          <Subheader />
        </div>
        <div className={b('jackpot-layout')}>
            <Statistic />
          </div>
        <div className={b('content')}>
          <div className={b('game-components')}>
            <div className={b('game-layout')}>
              {(isPause && periodType === 2)
                ? <Pause />
                : <>
                  <div className={b('vertical-animation')}>
                    <DotsAnimation isVertical={true} />
                    <div className={b('game-window-container')}>
                      <GameWindow />
                    </div>
                  </div>
                  <DotsAnimation isVertical={false} />
                </>
              }
            </div>
            <div className={b('basket-layout')}>
              <Basket />
            </div>
          </div>
        </div>
        <div className={b('history-layout')}>
          <History />
        </div>
      </React.Fragment>
        : <React.Fragment>
          <div className={b('left-header-layout')}>
            <Subheader />
          </div>
          <div className={b('jackpot-layout')}>
            <Statistic />
          </div>
          <div className={b('content')}>
            <div className={b('game-layout')}>
              {(isPause && periodType === 2)
                  ? <Pause />
                  : 
                  <>
                    <div className={b('vertical-animation')}>
                      <DotsAnimation isVertical={true} />
                      <div className={b('game-window-container')}>
                        <GameWindow />
                      </div>
                    </div>
                    <DotsAnimation isVertical={false} />
                  </>
                }
            </div>
          </div>
          <div className={b('basket-layout')}>
            <Basket />
          </div>
          <div className={b('history-layout')}>
            <History />
          </div>
        </React.Fragment>
    }
  </article>
}

export default Game;