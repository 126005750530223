import step1 from 'services/img/rules-step-01.png';
import bet from 'services/img/01-bet.png';
import step2 from 'services/img/rules-step-02.png';
import watch from 'services/img/02-watch.png';
import step3 from 'services/img/rules-step-03.png';
import cashout from 'services/img/03-cashout.png';
import arrow from 'services/img/red-arrow.png';

export const rules = (amountMax, currency) => `
<div style='
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 16px;
'>
    <div>
        Aviator is a new generation of iGaming entertainment. You can win many times more, in seconds! Aviator is built on a provably fair system, which is currently the only real guarantee of honesty in the gambling industry.
    </div>
    <div style="text-transform: uppercase;"> How to play </div>
    <div style='width: 100%; height: 30.75%;'>
    <iframe
        frameborder="0"
        allowfullscreen
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        src="https://www.youtube.com/embed/PZejs3XDCSY?playsinline=1"
        style="width: 100%; height: 100%;"
    ></iframe>
    </div> 
    <div style="padding: 60px 0px 0px 10px;">
        Aviator is as easy to play as 1-2-3: 
    </div>
    <h3 style="font-weight: 700; font-size: 24px; margin-top: 20px; padding-left: 40px;">01</h3>
    <img style="border: 1px solid #4e4e4e; border-radius: 20px; width: 240px; height: 140px; margin-left: 40px;" loading='lazy' src='${step1}' />
    <div style="margin: 0px 40px; display: flex; column-gap: 5px; align-items: center; justify-content: flex-start; color: white; font-size: 14px; font-weight: 700;">
        <img style="width: 36px; height: 36px;" loading='lazy' src='${bet}' />
        <div>
        <span style="text-transform: uppercase; color: red;">Bet</span>
        before take-off 
        </div>
    </div>
    <h3 style="font-weight: 700; font-size: 24px; margin-top: 20px; padding-left: 40px;">02</h3>
    <img style="border: 1px solid #4e4e4e; border-radius: 20px; width: 240px; height: 140px; margin-left: 40px;" loading='lazy' src='${step2}' />
    <div style="margin: 0px 40px; display: flex; column-gap: 5px; align-items: center; justify-content: flex-start; color: white; font-size: 14px; font-weight: 700;">
        <img style="width: 36px; height: 36px;" loading='lazy' src='${watch}' />
        <div>
        <span style="text-transform: uppercase; color: red;">Watch</span>
        as your Lucky Plane takes off and your winnings increase. 
        </div>
    </div>
    <h3 style="font-weight: 700; font-size: 24px; margin-top: 20px; padding-left: 40px;">03</h3>
    <img style="border: 1px solid #4e4e4e; border-radius: 20px; width: 240px; height: 140px; margin-left: 40px;" loading='lazy' src='${step3}' />
    <div style="margin: 0px 40px; display: flex; column-gap: 5px; align-items: center; justify-content: flex-start; color: white; font-size: 14px; font-weight: 700;">
        <img style="width: 36px; height: 36px;" loading='lazy' src='${cashout}' />
        <div>
        <span style="text-transform: uppercase; color: red;"> Cash out </span>
        before the plane disappears and wins X times more! 
        </div>
    </div>
    <div style="margin-top: 4px;">
    But remember, if you did not have time to Cash Out before the Lucky Plane flies away, your bet will be lost. Aviator is pure excitement! Risk and win. It’s all in your hands! 
    </div>
    <div style="margin-top: 20px;">
    More details 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    The win multiplier starts at 1x and grows more and more as the Lucky Plane takes off. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    Your winnings are calculated at the multiplier at which you made a Cash Out, multiplied by your bet. 
    </div>
    <div style="margin: 60px 0px 4px 0px;">  GAME FUNCTIONS  </div>
    <div> Bet & Cash Out </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    Select an amount and press the “Bet” button to make a bet. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    You can make two bets simultaneously, by adding a second bet panel. To add a second bet panel, press the plus icon, which is located on the top right corner of the first bet panel. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    Press the “Cash Out” button to cash out your winnings. Your win is your bet multiplied by the Cash Out multiplier. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    Your bet is lost, if you didn’t cash out before the plane flies away. 
    </div>
    <div> Auto Play & Auto Cash Out </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    Auto Play is activated from the “Auto” tab on the Bet Panel, by pressing the “Auto Play” button. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    In the Auto Play Panel, the “Stop if cash decreases by” option stops Auto Play, if the balance is decreased by the selected amount.  
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    In the Auto Play Panel, the “Stop if cash increases by” option stops Auto Play, if the balance is increased by the selected amount. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    In the Auto Play Panel, the “Stop if single win exceeds” option stops Auto Play, if a single win exceeds the selected amount. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    Auto Cash Out is available from the “Auto” tab on the Bet panel. After activation, your bet will be automatically cashed out when it reaches the multiplier entered 
    </div>
    <div> Live Bets & Statistics </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    On the left side of the game interface (or under the Bet Panel on mobile), is located the Live Bets panel. Here you can see all bets that are being made in the current round. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    In the “My Bets” panel you can see all of your bets and Cash Out information. 
    </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    In the “Top” panel, game statistics are located. You can browse wins by amount, or Cash Out multiplier, and see the biggest round multipliers. 
    </div>
    <div> Free bets </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    You can check the status of Free Bets, from the Game Menu > Free Bets. Free Bets are awarded by the operator, or by the Rain Feature. 
    </div>
    <div>  Return to Player  </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    The overall theoretical return to player is 97%. This means that on average, for every 100 rounds, every 3 rounds end with the Lucky Plane flying away at the very beginning of the round.  
    </div>
    <div> Other </div>
    <div style="margin: -6px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    If the internet connection is interrupted when the bet is active, the game will auto cash out with the current multiplier, and the winning amount will be added to your balance. 
    </div>
    <div style="margin: -6px 20px 16px 20px; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 5px; font-size: 14px; color: white;">
    <img style="width: 10px; height: 16px;" loading='lazy' src='${arrow}' />
    In the event of a malfunction of the gaming hardware/software, all affected game bets and payouts are rendered void and all affected bets are refunded. 
    </div>
</div>
`