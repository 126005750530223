import React from 'react';
import block from 'bem-cn';

import './LimitsItem.scss';

const b = block('limits-item');

const LimitsItem = ({ text, amount, notNeedBorder }) => {

    return <div className={b({ notNeedBorder: notNeedBorder })}>
        {text}
        <div className={b('limits-amount-container')}>{amount}</div>
    </div>
};

export default LimitsItem;