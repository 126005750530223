import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import close from 'services/img/close_gr.svg';
import LimitsItem from './LimitsItem';

import './GameLimits.scss';

const b = block('game-limits');

const GameLimits = ({ onClose }) => {
    const { locale } = useSelector(state => state.locale);
    const { limits } = useSelector(state => state.game);
    const { currency } = useSelector(state => state.auth);

    return <div className={b()} onClick={onClose}>
        <div className={b('container')} onClick={e => e.stopPropagation()}>
            <div className={b('header')}>
                {locale.gameLimits}
                <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={onClose} />
            </div>
            <div className={b('content')}>
                <div className={b('limits-container')}>
                    <LimitsItem text={`${locale.minimumBet}(${currency})`} amount={limits.min} />
                    <LimitsItem text={`${locale.maximumBet}(${currency})`} amount={limits.max} />
                    <LimitsItem text={`${locale.maximumWin}(${currency})`} amount={limits.win} notNeedBorder={true} />
                </div>
            </div>
        </div>
    </div>
};

export default GameLimits;