import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import { actions } from '../../';

import { icons } from 'services/img/icons';
import close from 'services/img/close_gr.svg';

import './EditUser.scss';

const b = block('edit-user');

const EditUser = ({ setIsOpen, isOpen }) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(state => state.locale);
  const { icon_id } = useSelector(state => state.auth);

  const updateUser = useCallback((id) => {
    dispatch(actions.userUpdate({ icon_id: +id }))
    setIsOpen(false);
  }, [dispatch, setIsOpen]);


  const onCloseButtonClick = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const iconsList = useMemo(() => Object.entries(icons).map((t, index) => <img
    className={b('icon', { active: icon_id.toString() === t[0]}).toString()}
    src={t[1]}
    key={index}
    loading='lazy'
    alt='icon'
    onClick={() => updateUser(t[0])}
  />), [icon_id, updateUser])

  return <article className={b()} onClick={onCloseButtonClick} >
    <div className={b('container')} onClick={e => e.stopPropagation()}>
      <div className={b('header')}>
        {locale.chooseAvatar}
        <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={onCloseButtonClick} />
      </div>
      <div className={b('content')}>
        <div className={b('icons-block')}>
          {iconsList}
        </div>
      </div>
      <div className={b('footer')}>
        <div className={b('close-button')} onClick={onCloseButtonClick}>{locale.close}</div>
      </div>
    </div>
</article>;
}

export default EditUser;