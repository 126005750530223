export default {
  roundID: 0,
  isProcess: false,
  coefsHistory: [],
  cashedBets: [],
  topWins: [],
  periodType: null, // 1 - playing, 2 - pause
  coef: 0,
  pouseDuration: 0,
  restartDuration: 0,
  limits: {
    min: 0,
    max: 0,
    win: 0,
  },
  betAmountInRound: 0,
  styleCode: 'default',
  winAmount: 0,
  winCoef: 0,
  isWinPopup: false,
  buttonClick: 0,
  isRulesOpen: false,
}