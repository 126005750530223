import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector } from 'react-redux';

import { Rules, RulesMini } from 'components/Rules';
import Menu from 'components/Menu';
import menu from 'services/img/burger.svg';
import logo from 'services/img/logo.svg';
import question from 'services/img/question.svg';
import TicketsComponent from './TicketsComponent';

import './Subheader.scss';

const Subheader = () => {
  const b = block('subheader');
  const { platform, balance, currency } = useSelector(state => state.auth);
  const { locale } = useSelector(state => state.locale);
  const { isRulesOpen } = useSelector(state => state.game);
  const [isOpen, setOpen] = useState(false);
  const [freeBetsIsOpen, setFreeBetsIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onMiniRulesOpenClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onMiniRulesCloseClick = useCallback(event => {
    setOpen(false);
  }, []);

  const onOpenMenu = useCallback(() => {
    setMenuIsOpen(true);
  }, []);

  const onCloseMenu = useCallback(() => {
    setMenuIsOpen(false);
  }, []);

  return <header className={b()}>
    <SVGInline className={b('logo').toString()} svg={logo} />
    <div className={b('settings')}>
      <div className={b('rules-mini', {isMobile: platform === 'mobile'})} onClick={onMiniRulesOpenClick}>
        <SVGInline svg={question} className={b('question').toString()} />
        {platform === 'desktop' ? locale.miniRulesHeader : null}
      </div>
      <div className={b('balance')}>
        {balance.toFixed(2)}
        <div className={b('currency')}>{currency}</div>
      </div>
      <div className={b('menu')}>
        <SVGInline svg={menu} className={b('menu-icon').toString()} onClick={onOpenMenu} />
      </div>
    </div>
    <TicketsComponent onClose={() => setFreeBetsIsOpen(false)} isOpen={freeBetsIsOpen} />
    {isRulesOpen && <Rules />}
    {isOpen && <RulesMini onClose={onMiniRulesCloseClick} />}
    <Menu open={menuIsOpen} onClose={onCloseMenu} />
  </header>
}

export default Subheader;