import React from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector } from 'react-redux';

import home from 'services/img/home.svg';

import './HomeButton.scss';

const b = block('home-button');

const  HomeButton = () => {
    const { locale } = useSelector(state => state.locale);
    const homeUrl = useSelector(state => state.auth.homeUrl);

    return <a className={b()} href={homeUrl}>
        <SVGInline svg={home} className={b('home-icon').toString()} />
        <div className={b('home-text')}>{locale.home}</div>
    </a>
};

export default HomeButton;