import { rules } from './rules';

export const locale = {
  // mini rules
  miniRulesHeader: 'How to play?',
  rulesDetail: 'detailed rules',
  bet01: 'Place a bet, or even two at the same time, and wait for the round to begin.',
  watch02: 'Keep an eye out for the lucky plane. Your win is the stake multiplied by a coefficient of the lucky plane.',
  cashout03: 'Get out before the plane disappears, and the money will be yours!',
  rulesHtml: rules,

  //menu
  change: 'Change',
  avatar: 'Avatar',
  chooseAvatar: 'CHOOSE GAME AVATAR',
  close: 'Close',
  home: 'Home',
  sound: 'Sound',
  music: 'Music',
  animation: 'Animation',
  freeBets: 'Free bets',
  gameRules: 'Game rules',
  myBetHistory: 'My bet history',
  gameLimits: 'Game limits',
  freeBetsTitle: 'FREE BETS MANAGEMENT',
  playWithCash: 'Play with cash',
  activeFreeBets: 'ACTIVE FREE BETS',
  archive: 'Archive',
  noActiveBets: 'No Active Free Bets. Yet!',
  back: 'Back',
  nothingYet: 'Nothing yet',
  minimumBet: 'Minimum bet',
  maximumBet: 'Maximum bet',
  maximumWin: 'Maximum win for one bet',

  //game window text
  waitingNextRound: 'Waiting for next round',
  flewAway: 'Flew away!',

  //basket
  auto: 'Auto',
  autoPlay: 'Auto Play',
  autoCashOut: 'Auto Cash Out',
  autoPlayOptions: 'Auto play options',
  roundsNum: 'Number of Rounds:',
  cashout: 'Cashout',
  cancelBet: 'Cancel',

  //auto bet options 
  ifCashDecrease: 'Stop if cash decreases by',
  ifCashIncrease: 'Stop if cash increases by',
  ifWinExeed: 'Stop if single win exceeds',
  reset: 'Reset',
  start: 'Start',
  emptyOptError: 'Please, specify decrease or exceed stop point',
  zeroStopPointError: `Can't set 0.00 as stop point`,
  zeroRoundNumber: 'Please, set number of rounds',
  stop: 'Stop',

  // history
  bets: 'All Bets',
  myBets: 'My bets',
  topBets: 'Top',
  totalBets: 'Total bets',
  date: 'Date',
  bet: 'Bet',
  cashOut: 'Cash out',
  loadMore: 'Load more',
  poweredBy: 'Powered by',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  cashedOut: 'Cashed out',
  roundHistory: 'Round history',
  user: 'User',

  //notification
  cashedOutMessages: 'You have cashed out!',
  win: 'Win',
}