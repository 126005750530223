import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector } from 'react-redux';

import AutoBetSettings from './AutoBetSettings';
import Button from 'components/Button';
import close from 'services/img/close.svg';

import './AutoBetItem.scss';

const b = block('auto-bet-item');
const countsArray = [10, 20, 50, 100];

const AutoBetItem = ({ 
    validateTempAmount, 
    validateChangeAmount, 
    onClose,
    turnOnAutoBet,
    setAutoBetCount,
    resetAutoBetOptions,
    setAutoBetApplyOptions,
    autoBetOptions,
    setAutoBetOptions,
}) => {
    const { locale } = useSelector(state => state.locale);
    const errorDictionary = {
        'emptyOptions': locale.emptyOptError,
        'zeroStopPoint': locale.zeroStopPointError,
        'zeroRoundNum': locale.zeroRoundNumber,
    };
    const [chosenBetCount, setChosenBetCount] = useState(101);
    const [isError, setIsError] = useState(false);
    const [errorType, setErrorType] = useState('');
    const balance = useSelector(state => state.auth.balance);
    const { roundID } = useSelector(state => state.game);

    const {
        isDecreaseStop,
        decreaseStopAmount,
        isIncreaseStop,
        increaseStopAmount,
        isWinExceedsStop,
        winExceedsStopAmount,
    } = autoBetOptions;

    const changeIsDecreaseStop = useCallback(() => {
        setAutoBetOptions({ ...autoBetOptions, isDecreaseStop: !autoBetOptions.isDecreaseStop })
    }, [autoBetOptions, setAutoBetOptions]);

    const changeIsIncreaseStop = useCallback(() => {
        setAutoBetOptions({ ...autoBetOptions, isIncreaseStop: !autoBetOptions.isIncreaseStop })
    }, [autoBetOptions, setAutoBetOptions]);

    const changeIsWinExceedsStop = useCallback(() => {
        setAutoBetOptions({ ...autoBetOptions, isWinExceedsStop: !autoBetOptions.isWinExceedsStop })
    }, [autoBetOptions, setAutoBetOptions]);

    const handleDescreaseAmountChange = useCallback(
        value => setAutoBetOptions({ ...autoBetOptions, decreaseStopAmount: value}),
        [autoBetOptions, setAutoBetOptions]
    );

    const handleIncreaseAmountChange = useCallback(
        value => setAutoBetOptions({ ...autoBetOptions, increaseStopAmount: value}),
        [autoBetOptions, setAutoBetOptions]
    );


    const handleWinExceedsAmountChange = useCallback(
        value => setAutoBetOptions({ ...autoBetOptions, winExceedsStopAmount: value}),
        [autoBetOptions, setAutoBetOptions]
    );

    const onPlusDecreaseAmountClick = useCallback(() => {
        validateChangeAmount(+decreaseStopAmount + 0.1, handleDescreaseAmountChange);
    }, [handleDescreaseAmountChange, decreaseStopAmount]);

    const onPlusIncreaseAmountClick = useCallback(() => {
        validateChangeAmount(+increaseStopAmount + 0.1, handleIncreaseAmountChange);
    }, [handleIncreaseAmountChange, increaseStopAmount]);

    const onPlusWinExceedsAmountClick = useCallback(() => {
        validateChangeAmount(+winExceedsStopAmount + 0.1, handleWinExceedsAmountChange)
    }, [handleWinExceedsAmountChange, winExceedsStopAmount]);

    const onMinusDecreaseAmountClick = useCallback(() => {
        validateChangeAmount(+decreaseStopAmount - 0.1, handleDescreaseAmountChange)
    }, [handleDescreaseAmountChange, decreaseStopAmount]);

    const onMinusIncreaseAmountClick = useCallback(() => {
        validateChangeAmount(+increaseStopAmount - 0.1, handleIncreaseAmountChange)
    }, [handleIncreaseAmountChange, increaseStopAmount]);

    const onMinusWinExceedsAmountClick = useCallback(() => {
        validateChangeAmount(+winExceedsStopAmount - 0.1, handleWinExceedsAmountChange)
    }, [handleWinExceedsAmountChange, winExceedsStopAmount]);

    const onBetCounterItemClick = useCallback(value => {
        setChosenBetCount(value);
    }, []);

    const onResetButtonClick = useCallback(() => {
        resetAutoBetOptions();
        setChosenBetCount(101);
        setIsError(false);
    }, []);

    const onCloseError = useCallback(() => {
        setIsError(false);
    }, []);

    const onStartButtonClick = useCallback(() => {
        if (chosenBetCount === 101) {
            setIsError(true);
            setErrorType('zeroRoundNum');
        }
        else if (!isDecreaseStop && !isIncreaseStop && !isWinExceedsStop) {
            setIsError(true);
            setErrorType('emptyOptions');
        } else if (isDecreaseStop && decreaseStopAmount === 0) {
            setIsError(true);
            setErrorType('zeroStopPoint');
        } else if (isIncreaseStop && increaseStopAmount === 0) {
            setIsError(true);
            setErrorType('zeroStopPoint');
        } else if (isWinExceedsStop && winExceedsStopAmount === 0) {
            setIsError(true);
            setErrorType('zeroStopPoint');
        } else {
            setIsError(false);
            setAutoBetCount(chosenBetCount);
            turnOnAutoBet();
            onClose();
            setAutoBetApplyOptions({
                balance,
                roundID,
            });
        };
    }, [
        chosenBetCount,
        isDecreaseStop,
        isIncreaseStop,
        isWinExceedsStop,
        decreaseStopAmount,
        increaseStopAmount,
        winExceedsStopAmount,
        roundID,
    ]);


    return <div className={b()}>
        <div className={b('container')} onClick={e => e.stopPropagation()}>
            <div className={b('header')}>
                {locale.autoPlayOptions}
                <SVGInline className={b('svg').toString()} svg={close} onClick={onClose} />
            </div>
            <div className={b('content')}>
                {isError && <div className={b('error-container')}>
                    {errorDictionary[errorType]}
                    <SVGInline svg={close} className={b('svg', { isError: true }).toString()} onClick={onCloseError} />
                </div>}
                <div className={b('auto-bet-count')}>
                    {locale.roundsNum}
                    <div className={b('count-container')}>
                        {countsArray.map((count, i) => <div key={i} className={b('count-item', { isChosen: count === chosenBetCount })} onClick={() => onBetCounterItemClick(count)}>{count}</div>)}
                    </div>
                </div>
                <AutoBetSettings
                    switchValue={isDecreaseStop}
                    switchValueFunc={changeIsDecreaseStop}
                    text={locale.ifCashDecrease}
                    changeAmountValue={decreaseStopAmount.toFixed(2)}
                    onPlusClick={isDecreaseStop ? onPlusDecreaseAmountClick : f => f}
                    onMinusClick={isDecreaseStop ? onMinusDecreaseAmountClick : f => f}
                    onClick={isDecreaseStop ? () => validateChangeAmount(decreaseStopAmount, handleDescreaseAmountChange) : f => f}
                    onChange={isDecreaseStop ? e => validateTempAmount(e.currentTarget.value, handleDescreaseAmountChange) : f => f}
                />
                <AutoBetSettings
                    switchValue={isIncreaseStop}
                    switchValueFunc={changeIsIncreaseStop}
                    text={locale.ifCashIncrease}
                    changeAmountValue={increaseStopAmount.toFixed(2)}
                    onPlusClick={isIncreaseStop ? onPlusIncreaseAmountClick : f => f}
                    onMinusClick={isIncreaseStop ? onMinusIncreaseAmountClick : f => f}
                    onClick={isIncreaseStop ? () => validateChangeAmount(increaseStopAmount, handleIncreaseAmountChange) : f => f}
                    onChange={isIncreaseStop ? e => validateTempAmount(e.currentTarget.value, handleIncreaseAmountChange) : f => f}
                />
                <AutoBetSettings
                    switchValue={isWinExceedsStop}
                    switchValueFunc={changeIsWinExceedsStop}
                    text={locale.ifWinExeed}
                    changeAmountValue={winExceedsStopAmount.toFixed(2)}
                    onPlusClick={isWinExceedsStop ? onPlusWinExceedsAmountClick : f => f}
                    onMinusClick={isWinExceedsStop ? onMinusWinExceedsAmountClick : f => f}
                    onClick={isWinExceedsStop ? () => validateChangeAmount(winExceedsStopAmount, handleWinExceedsAmountChange) : f => f}
                    onChange={isWinExceedsStop ? e => validateTempAmount(e.currentTarget.value, handleWinExceedsAmountChange) : f => f}
                />
            </div>
            <div className={b('footer')}>
                <Button
                    text={locale.reset}
                    color='orangeReset'
                    callBack={onResetButtonClick}
                />
                <Button
                    text={locale.start}
                    color='greenStart'
                    callBack={onStartButtonClick}
                />
            </div>
        </div>
    </div>
};

export default AutoBetItem;