import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import close from 'services/img/close_gr.svg';
import history from 'services/img/history.svg';
import ticket from 'services/img/ticket.svg';

import './FreeBets.scss';

const b = block('free-bets');

const FreeBets = ({ onClose }) => {
    const { locale } = useSelector(state => state.locale);
    const [isArchive, setIsArchive] = useState(false);

    const onArchiveButtonClick = useCallback(() => {
        setIsArchive(!isArchive);
    }, [isArchive]);

    return <div className={b()} onClick={onClose}>
        <div className={b('container')} onClick={e => e.stopPropagation()}>
            <div className={b('header')}>
                {locale.freeBetsTitle}
                <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={onClose} />
            </div>
            <div className={b('content')}>
                {!isArchive && <div className={b('play-with-cash')}>
                    <div className={b('circle')}>
                        <div className={b('center')}></div>
                    </div>
                    {locale.playWithCash}
                </div>}
                <div className={b('active-free-bets')}>
                    {isArchive ? locale.archive : locale.activeFreeBets}
                    <div className={b('archive-button')} onClick={onArchiveButtonClick}>
                        <SVGInline svg={isArchive ? close : history} className={b('archive-icon').toString()} />
                        {isArchive ? locale.back : locale.archive}
                    </div>
                </div>
                <div className={b('tickets-container')}>
                    <SVGInline className={b('tickets').toString()} svg={ticket} />
                    <div className={b('ticket-text')}>{isArchive ? locale.nothingYet : locale.noActiveBets}</div>
                </div>
            </div>
        </div>
    </div>
};

export default FreeBets;