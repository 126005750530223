import React, { useEffect, useState, useCallback, Suspense } from 'react';
import block from 'bem-cn';
import { Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import SVGInline from 'react-svg-inline';

import { languages } from 'services/locale';
import { actions as authAction } from './features/Auth';
import { actions as localeActions } from './features/locale';
import { Rules } from './components/Rules';
import { Game } from './features/Game';
import { SocketErrorBoundary } from 'features/SocketErrorBoundary';
import Notify from 'features/Notify/view';
import preloader from 'services/img/preloader.png';

import './App.scss';

const Music = React.lazy(() => import('./components/Music'));

const LOADER_STYLE = { 
  display: 'inline-block', 
  position: 'absolute', 
  top: '50%', 
  left: '50%', 
  transform: 'translate(-50%, -50%)', 
  zIndex: 100,  
  height: '64px'
};

const App = ({ lang, homeUrl }) => {
  const b = block('app');
  const [isUserActivity, setUserActivity] = useState(false);
  const isAuth = useSelector(state => state.auth.isAuth);
  const tempToken = useSelector(state => state.auth.token);
  const tempHomeUrl = useSelector(state => state.auth.homeUrl);
  const tempLang = useSelector(state => state.locale.lang);
  const { isGameSocketError, gameSocketError } = useSelector(state => state.socketError);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const userActivityClick = useCallback(f => isUserActivity ? f :
    setUserActivity(true),
  [isUserActivity]);

  useEffect(() => {
    if (location.search.indexOf(tempToken) === -1 && tempToken !== null) {
      history.replace(`${location.pathname}?token=${tempToken}&lang=${tempLang}&homeUrl=${tempHomeUrl}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, tempToken])

  useEffect(() => {
    dispatch(authAction.setPlatform());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.userAgent, dispatch])

  useEffect(() => {
    dispatch(localeActions.changeLang(languages[lang] ? lang : 'fr'))
  }, [dispatch, lang])

  useEffect(() => {
    dispatch(authAction.setHomeUrl(homeUrl))
  }, [dispatch, homeUrl])

  return isGameSocketError ? <SocketErrorBoundary error={gameSocketError} /> : (
    <React.Fragment>
      {!isAuth && <img src={preloader} className='preloader' loading='lazy' alt='preloader' />}
      <PulseLoader loading={!isAuth} color="red" width={5} height={5} margin={5} css={LOADER_STYLE} />
      {isAuth ? <div className={b()} onMouseMove={userActivityClick} onTouchMove={userActivityClick} >
        <Switch>
          <Redirect from="/" to="/game" exact />
          <Route exact key="/" path="/" component={Game} />
          <Route exact key="/game" path="/game" component={Game} />
          <Route exact key="/rules" path="/rules" component={Rules} />
        </Switch>
      </div> : null}
      {Boolean(isUserActivity) && <Suspense fallback={null}>
        <Music />
        <Notify />
      </Suspense>}
    </React.Fragment>

  );
}

export default App;
