import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import SwitchBox from 'components/SwitchBox';
import ChangeAmount from 'components/ChangeAmount';

import './AutoBetSettings.scss';

const b = block('auto-bet-settings');

const AutoBetSettings = ({ 
    text, 
    switchValue, 
    switchValueFunc, 
    changeAmountValue,
    onPlusClick,
    onMinusClick,
    onClick,
    onChange,
}) => {
    const { currency } = useSelector(state => state.auth);

    return <div className={b()}>
        <div className={b('switch-settings-container')}>
            <SwitchBox value={switchValue} changeValue={switchValueFunc} />
            {text}
        </div>
        <div className={b('change-amount-container', { notActive:  !switchValue})}>
            <ChangeAmount 
                value={changeAmountValue} 
                onPlusClick={onPlusClick} 
                onMinusClick={onMinusClick}
                onClick={onClick}
                onChange={onChange} 
            />
            {currency}
        </div>
    </div>
};

export default AutoBetSettings;