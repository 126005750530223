import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import styles from '../../../data';
import AirplaneSprite from './AirplaneSprite';


import './Firework.scss';

const b = block('firework');

const Firework = ({ isPause }) => {
  const periodType = useSelector(state => state.game.periodType);
  const styleCode = useSelector(state => state.game.styleCode);
  const { isAnimations } = useSelector(state => state.settings);

  return <React.Fragment>
    {isAnimations && <div className={b('animation-container', {
      isAnimation: !isPause,
      isHorizon: styles[styleCode].isHorizon,
      isPauseAnimation: periodType === 2 && !isPause
    })}>
      <div className={b({
        isHorizon: styles[styleCode].isHorizon,
        notNeedAfterAnimation: styles[styleCode].notNeedAfterAnimation,
        isEqualMarginAndHeight: styles[styleCode].isEqualMarginAndHeight,
        notNeedCoefTop: styles[styleCode].notNeedCoefTop,
      })}>
        {periodType === 1
          ? <div className={b('rocket', { isPauseAnimation: periodType === 2 && !isPause, hideAnimation: isAnimations })}><AirplaneSprite /></div>
          : <div className={b('blow')}> <AirplaneSprite /></div>}
      </div>
    </div>}
  </React.Fragment>;
}

export default Firework;