import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = ({
  callBack = f => f,
  text = '',
  color = 'dark',
  size = 'default',
  isDisabled = false,
  isUppercase = false,
  isBold = false,
  fontSize = 'normal',
  isBlock = false,
  isVertical = false,
  link }) => {
  const b = block('button');
  
  const button = <button
    className={b({ color }, { size: link ? 'container' : size, isUppercase, isBold, isBlock, fontSize, isVertical, isDisabled })}
    onClick={callBack}
    disabled={isDisabled}>
      <span className={b('text')}>{isVertical ? text.split('').join(' ') : text}</span>
  </button>;

  return link ? <Link to={link} className={b('layout', { size })}>
    {button}
  </Link> : button
}

export default Button;

