import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { actions } from '../';

import close from 'services/img/close_gr.svg';

import './MyBetsHistory.scss';

const b = block('my-bets-history');

const MyBetsHistory = ({ onClose }) => {
    const dispatch = useDispatch();
    const { locale } = useSelector(state => state.locale);
    const { currency } = useSelector(state => state.auth);
    const { myBets } = useSelector(state => state.history);
    const { roundID } = useSelector(state => state.game);
    const [betArrayLength, setBetArrayLength] = useState(10);

    useEffect(() => {
        dispatch(actions.getMyBets());
    }, [dispatch, roundID]);

    const onLoadMoreButtonClick = useCallback(() => {
        setBetArrayLength(prev => prev + 5);
    }, []);

    const myBetsShort = useMemo(() => myBets?.slice(0, betArrayLength - 1) || [], [myBets, betArrayLength]);

    const resultList = useMemo(() => (
        myBetsShort.map((bet, i) => (
            <div key={i} className={b('item', { isWin: bet.win_amount > 0 })}>
                <div className={b('created-date')}>
                    {dayjs(bet.created_at).format('HH:mm DD-MM-YY')}
                </div>
                <div className={b('amount')}>
                    {bet.bet_amount.toFixed(2)}
                </div>
                <div className={b('coeff')}>
                    {`${bet.coefficient.toFixed(2)}x`}
                </div>
                <div className={b('win-amount')}>
                    {bet.win_amount.toFixed(2)}
                </div>
            </div>
        ))
    ), [myBetsShort]);

    return <div className={b()} onClick={onClose}>
        <div className={b('container')} onClick={e => e.stopPropagation()}>
            <div className={b('header')}>
                {locale.myBetHistory}
                <SVGInline svg={close} className={b('svg').toString()} alt='close' onClick={onClose} />
            </div>
            <div className={b('labels')}>
                <div className={b('label', { isDate: true })}>{locale.date}</div>
                <div className={b('label', { isBet: true })}>{`${locale.bet}, ${currency}`}</div>
                <div className={b('label', { isCoef: true })}>X</div>
                <div className={b('label', { isCashOut: true })}>{`${locale.cashOut}, ${currency}`}</div>
            </div>
            <div className={b('content')}>
                {resultList}
            </div>
            <div className={b('footer')}>
                <div className={b('load-more-button')} onClick={onLoadMoreButtonClick}>{locale.loadMore}</div>
            </div>
        </div>
    </div>
};

export default MyBetsHistory;