import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import BasketItem from './BasketItem';

import './Basket.scss';

const b = block('basket');

const Basket = () => {
  const lang = useSelector(state => state.locale.lang);
  const periodType = useSelector(state => state.game.periodType);
  const isProcess = useSelector(state => state.game.isProcess);
  const [baskets, setBaskets] = useState([true, true]);

  const removeBasket = useCallback((i) => {
    setBaskets(baskets.filter((_, index) => index !== i));
  }, [baskets]);

  const addBasket = useCallback(() => {
    setBaskets([...baskets, true]);
  }, [baskets]);

  const isAlone = baskets.filter(t => t === true).length === 1;

  // Not one basket
  const isDouble = useMemo(() => !isAlone, [isAlone]);

  return (
    <article className={b({ isDouble, lang })}>
      {baskets.map((t, i) => (
        <BasketItem
          key={i}
          index={i}
          isAlone={isAlone}
          isProcess={isProcess}
          periodType={periodType}
          button={isAlone ?  i === 0
            ? <div className={b('more-basket')} onClick={addBasket}></div> : null
            : i === 1 ? <div className={b('more-basket', {isRemove: true})} onClick={() => removeBasket(i)}></div> : null
          }
        />
      ))}
    </article>
  );
};

export default Basket;
