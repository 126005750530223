import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { categories } from './data';

import Button from 'components/Button';
import MyBets from './MyBets';
import Bets from './Bets';
import TopWins from './TopWins';
import Footer from './Footer';

import './History.scss';

const History = ({ isClosingHistory }) => {
  const b = block('history');
  const [category, changeCategory] = useState(categories.bets || '')
  const locale = useSelector(state => state.locale.locale);

  const buttons = Object.entries(categories).map(t => (
    <Button
      key={t[0]}
      text={(locale[t[1].textIdent])}
      color={category.id === t[1]?.id ? 'orange' : 'dark'}
      callBack={() => changeCategory(t[1])} />
  ))

  const neededComponent = useMemo(() => {
    switch (category.id) {
      case 0:
        return <Bets />;
      case 1:
        return <MyBets />;
      case 2:
        return <TopWins />;
      default:
        return null;
    }
  }, [category.id])

  return <article className={b({ isClosingHistory })}>
    <div className={b('buttons-container')}>
      {buttons}
    </div>
    <div className={b('content-container')}>
      <div className={b('component-container')}>
        {neededComponent}
      </div>
      <div className={b('footer-container')}>
        <Footer />
      </div>
    </div>
    
  </article>
}

export default History;