import {
  compose,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';

import { reducer as localeReducer } from './features/locale';
import { reducer as authReducer } from './features/Auth';
import { reducer as gameReducer } from './features/Game';
import { reducer as historyReducer } from './features/History';
import { reducer as settingsReducer } from './features/Settings';
import { reducer as socketErrorReducer } from './features/SocketErrorBoundary';
import { reducer as notifyReducer } from './features/Notify';


function configureStore(extra) {
  const middlewares = [
    thunk.withExtraArgument({ ...extra, socket: extra.socket, chatSocket: extra.chatSocket }),
  ];

  const reducer = createReducer();

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(...middlewares),
      persistState(['settings']),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : (arg => arg),
    ),
  );

  extra.socket.addSocketHandlers(store.dispatch)
  extra.chatSocket.addSocketHandlers(store.dispatch)

  return store;
}

function createReducer() {
  return combineReducers({
    locale: localeReducer,
    auth: authReducer,
    game: gameReducer,
    history: historyReducer,
    settings: settingsReducer,
    socketError: socketErrorReducer,
    notify: notifyReducer,
  });
}


export { createReducer };
export default configureStore;
