import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as historyActions } from 'features/History';

import {Firework} from './Firework';
import Coef from './Firework/Coef';

import styles from '../../data';

import './GameWindow.scss';

const b = block('game-window');

const GameWindow = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const [isPause, setPause] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { periodType, styleCode, coef } = useSelector(state => state.game);
  const isAnimations = useSelector(state => state.settings.isAnimations);
  const [gradientColor, setGradientColor] = useState('rgba(52 180, 255, 0.5)');

  useEffect(() => {
    if (coef < 2) {
      setGradientColor('rgba(52 180, 255, 0.5)');
    } else if (coef < 10) {
      setGradientColor('rgba(145, 62, 248, 0.5)');
    } else setGradientColor('rgba(192, 23, 180, 0.5)');
  }, [coef]);

  useEffect(() => {
    if (periodType === 2) {
      setPause(false)
      dispatch(historyActions.clearBets());
      if (!timeoutId) {
        setTimeoutId(setTimeout(() => setPause(true), 2250));
      }
    }
    if (periodType === 1) {
      setPause(false)
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null)
      }
    }
  }, [periodType, dispatch, timeoutId])

  const endObject = useMemo(() => styles[styleCode].endObjectAfter ? <img
    className={b('end-image')}
    src={periodType === 2 && !isPause ? styles[styleCode].endObjectAfter : null}
    alt="" /> : null, [isPause, periodType, styleCode])

  return <>
    <div className='bg'></div>
    <article className={b({
      isAnimation: !isPause && isAnimations,
      isPauseAnimation: periodType === 2 && !isPause,
      styleCode: styleCode || 'default'
    })}
      style={{background: `radial-gradient(${gradientColor} 0%, rgba(0,0,0,0) 55%)`}}
    >
      <Firework  isPause={isPause} />
      {periodType === 2 && <div className={b('text')}>{locale.flewAway}</div>}
      <Coef />
      {endObject}
    </article>
  </>

}

export default GameWindow;