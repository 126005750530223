import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import { NotifyModel } from 'services/models/NotifyModel';
import close from 'services/img/close.svg';

import './Notification.scss';

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.notifyRef = React.createRef();
    this.state = {
      isDeleting: false,
      isDeleted: false,
    };
  }

  static propTypes = {
    text: PropTypes.string.isRequired,
    coef: PropTypes.string.isRequired,
    text2: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    needClose: PropTypes.bool.isRequired,

    deleteNotify: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { needClose, closeTime } = this.props;
    const touchListener = new NotifyModel(() => this._hideNotify(), this.notifyRef.current);
    touchListener.init();
    if (needClose) {
      setTimeout(this._hideNotify, closeTime);
    }
  }

  render() {
    const b = block('notify-item');
    const { text, coef, text2, amount, type } = this.props;
    return (
      <div className={b({ deleting: this.state.isDeleting }, { type })} ref={this.notifyRef}>
        <div className={b('container')}>
          <div className={b('text')}>{text}</div>
          {`${coef}x`}
        </div>
        <div className={b('container', {isAmount: true})}>
          <div className={b('text', {isAmount: true})}>{text2}</div>
          {amount}
        </div>
        <SVGInline
          className={b('close').toString()}
          svg={close}
          onClick={() => this._hideNotify()}
        />
      </div>
    );
  }

  _hideNotify = () => {
    const { deleteNotify, id } = this.props;
    if (!this.state.isDeleted) {
      this.setState({ isDeleting: true },
        () => setTimeout(() => {
          this.setState({ isDeleted: true });
          deleteNotify(id);
        }, 700));
    }
  }
}

export default Notification;
