import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/Game';

import close from 'services/img/close_gr.svg';

import './Rules.scss';

const Rules = () => {
  const b = block('rules');
  const dispatch = useDispatch();
  const { locale } = useSelector(state => state.locale);
  const { currency } = useSelector(state => state.auth);
  const { win } = useSelector(state => state.game.limits);

  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
      setIsClosing(true);
      setTimeout(() => {
          setIsClosing(false);
          dispatch(actions.setRulesOpen(false));
      }, 300);
  }, [dispatch]);

  return <article className={b()} onClick={handleClose}>
    <div className={b('container', { isClose: isClosing })} onClick={e => e.stopPropagation()}>
      <div className={b('header')}>
        {locale.gameRules}
        <SVGInline svg={close} className={b('svg').toString()} alt='close'  onClick={handleClose} />
      </div>
      <div className={b('content')}>
        <section className={b('text')} dangerouslySetInnerHTML={{ __html: locale.rulesHtml(win, currency) }} />
      </div>
    </div>
  </article>
}

export default Rules;