import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Coef.scss';

const b = block('coef');

const Coef = () => {
  const { coef, periodType } = useSelector(state => state.game);

  return <div className={b({isCrash: periodType === 2 })}>
    {`${coef.toFixed(2)}x`}
  </div>;
}

export default Coef;