import React, { memo, useEffect, useState } from 'react';
import block from 'bem-cn';

import './AirplaneSprite.scss';

const b = block('airplane-sprite');

const frameSize = [150, 74];
const frameMatrix = [1, 2];

const AirplaneSprite = () => {
    const [x, setX] = useState(0);

    useEffect(() => {
        let i = 1;

        const intervalId = setInterval(() => {
            if (i >= frameMatrix[0] * frameMatrix[1]) {
                i = 0;
                return;
            }

            const currentElement = i % frameMatrix[1];
            const x = currentElement * frameSize[0];

            setX(x);

            i++;
        }, 150);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    return (
        <div className={b()}>
            <div className={b('image')} style={{ backgroundPositionX: -x }} />
        </div>
    );
};

export default memo(AirplaneSprite);