import React from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import Input from 'components/Input';
import plus from 'services/img/plus-in-circle.svg';
import minus from 'services/img/minus-in-circle.svg';

import './ChangeAmount.scss';

const b = block('change-amount');

const ChangeAmount = ({ 
    value,
    onClick,
    onPlusClick,
    onMinusClick,
    onChange,
 }) => {
    return <div className={b()}>
        <SVGInline svg={minus} className={b('icon').toString()} onClick={onMinusClick} />
        <div className={b('input-conteiner')}>
            <Input
                value={value}
                onChange={onChange}
                onBlur={onClick}
                unBorder
                centerText
            />
        </div>
        <SVGInline svg={plus} className={b('icon').toString()} onClick={onPlusClick} />
    </div>
};

export default ChangeAmount;