import React, { useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'features/Game';

import close from 'services/img/close.svg';

import './RulesMini.scss';

const b = block('rules-mini')

const RulesMini = ({ onClose }) => {
    const dispatch = useDispatch();
    const { locale } = useSelector(state => state.locale);

    const onRulesClickOpen = useCallback(() => {
        onClose();
        dispatch(actions.setRulesOpen(true));
    }, [dispatch, onClose]);

    return <div className={b()} onClick={onClose}>
        <div className={b('container')} onClick={e => e.stopPropagation()}>
            <div className={b('header')}>
                {locale.miniRulesHeader}
                <SVGInline className={b('close').toString()} svg={close} onClick={onClose} />
            </div>
            <div className={b('content')}>
                <div className={b('video')}>
                    <iframe 
                        title='video rules'
                        className={b('video-frame')} 
                        frameBorder={0} allowfullscreen 
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                        src="https://www.youtube.com/embed/PZejs3XDCSY?playsinline=1"
                    />
                </div>
                <div className={b('item')}>
                    <div className={b('step')}>01</div>
                    <div className={b('text')}>{locale.bet01}</div>
                </div>
                <div className={b('item')}>
                    <div className={b('step', {isWatch: true})}>02</div>
                    <div className={b('text')}>{locale.watch02}</div>
                </div>
                <div className={b('item')}>
                    <div className={b('step', {isCashout: true})}>03</div>
                    <div className={b('text')}>{locale.cashout03}</div>
                </div>
            </div>
            <div className={b('header', {isFooter: true})}><div style={{cursor: 'pointer'}} onClick={onRulesClickOpen}>{locale.rulesDetail}</div></div>
        </div>
    </div>
};

export default RulesMini;