import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import dayjs from 'dayjs';

import { actions } from '../';

import './MyBets.scss';

const MyBets = () => {
  const b = block('my-bets');
  const locale = useSelector(state => state.locale.locale);
  const { myBets } = useSelector(state => state.history);
  const { roundID } = useSelector(state => state.game);
  const currency = useSelector(state => state.auth.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMyBets());
  }, [dispatch, roundID])

  const resultList = useMemo(() => (
    myBets.map((bet, i) => (
      <div key={`${bet.value}${bet.round_id}${i}`} className={b('item', { isWin: bet.win_amount > 0 })}>
        <div className={b('created-date')}>
          {dayjs(bet.created_at).format('HH:mm DD-MM-YY')}
        </div>
        <div className={b('amount')}>
          {bet.bet_amount.toFixed(2)}
        </div>
        <div className={b('coeff')}>
          {`${bet.coefficient.toFixed(2)}x`}
        </div>
        <div className={b('win-amount')}>
          {bet.win_amount.toFixed(2)}
        </div>
      </div>
    ))
  ), [b, myBets])

  return <section className={b()}>
    <div className={b('labels')}>
      <div className={b('label', { isDate: true })}>{locale.date}</div>
      <div className={b('label', { isBet: true })}>{`${locale.bet}, ${currency}`}</div>
      <div className={b('label', { isCoef: true })}>X</div>
      <div className={b('label', { isCashOut: true })}>{`${locale.cashOut}, ${currency}`}</div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default MyBets;