import React from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import './MenuItem.scss';

const b = block('menu-item');

const MenuItem = ({
    isNeedBorder,
    icon,
    text,
    actions,
    callBack,
    isPointer,
}) => {
    return <div className={b({isNeedBorder: isNeedBorder, isPointer: isPointer})} onClick={callBack}>
        <div className={b('lable')}>
            <SVGInline svg={icon} className={b('icon')} />
            {text}
        </div>
        {actions}
    </div>
};

export default MenuItem;
