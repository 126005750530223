import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import blades from 'services/img/pause_animation.svg';
import { AirplaneSprite } from 'features/Game/view/GameWindow/Firework';

import './Pause.scss';

const b = block('pause');

const Pause = () => {
  const { restartDuration } = useSelector(state => state.game)
  const { locale } = useSelector(state => state.locale);

  return <article className={b()}>
    <div className={b('aviator')}>
      <SVGInline svg={blades} alt='zippelin' className={b('icon').toString()} />
    </div>
    {locale.waitingNextRound}
    <div className={b('animation')}>
      <div className={b('progress')} style={{animationDuration: `${(restartDuration - 2250) / 1000}s`}}></div>
    </div>
    <div className={b('airplane')}>
      <AirplaneSprite />
    </div>
  </article>
}

export default Pause;