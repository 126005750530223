import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './StatisticComponent.scss';

const b = block('statistic-component');

const StatisticComponent = ({ setOpen }) => {
    const locale = useSelector(state => state.locale.locale);
    const coefsHistory = useSelector(state => state.game.coefsHistory);

    const coefs = useMemo(() => coefsHistory.map(coef => <div
        key={coef.game_id}
        className='bets-coef'
        style={{color: coef.crash_point < 2 ? '#34b4ff' : coef.crash_point < 10 ? '#913ef8' : '#c017b4'}}
      >{`${coef.crash_point}x`}</div>),[coefsHistory]);

    const onCloseClick = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <div className={b()} onClick={onCloseClick}>
            <div className={b('container')} onClick={e => e.stopPropagation()}>
                <div className={b('header')}>
                    {locale.roundHistory}
                </div>
                <div className={b('content')}>
                    {coefs}
                </div>
            </div>
        </div>
    );
};

export default StatisticComponent;