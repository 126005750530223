import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import fullStatictic from 'services/img/history_statistic.svg';
import arrow from 'services/img/statistic_arrow.svg';
import StatisticComponent from './StatisticComponent';

import './Statistic.scss';

const Statistic = () => {
  const b = block('statistic');
  const coefsHistory = useSelector(state => state.game.coefsHistory);
  const [isOpen, setOpen] = useState(false);

  const coefs = useMemo(() => coefsHistory.map(coef => <div
    key={coef.game_id}
    className={b('coef')}
    style={{ color: coef.crash_point < 2 ? '#34b4ff' : coef.crash_point < 10 ? '#913ef8' : '#c017b4' }}
  >{`${coef.crash_point}x`}</div>), [b, coefsHistory]);

  const onShowFullStatisticButtonClick = useCallback(() => {
    setOpen(prev => prev === true ? false : true);
  }, []);

  return <>
    <section className={b()}>
      <div className={b('coefs-container')}>
        {coefs}
      </div>
      <div className={b('statistic-button')} onClick={onShowFullStatisticButtonClick}>
        <SVGInline svg={fullStatictic} className={b('history', { isOpen }).toString()} />
        <SVGInline svg={arrow} className={b('arrow', { isOpen }).toString()} />
      </div>
    </section>
    {isOpen ? <StatisticComponent setOpen={setOpen} /> : null}
  </>
}

export default Statistic;