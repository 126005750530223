import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'features/Settings';
import { actions as gameActions } from 'features/Game';

import { getIconById } from 'services/img/icons';

import changeIcon from 'services/img/change-icon.svg';
import soundIcon from 'services/img/sound.svg';
import musicIcon from 'services/img/music.svg';
import animationIcon from 'services/img/animation.svg';
import star from 'services/img/star-menu.svg';
import rules from 'services/img/rules.svg';
import history from 'services/img/history.svg';
import limits from 'services/img/limits.svg';
import { EditUser } from 'features/Auth';
import HomeButton from 'components/HomeButton';
import SwitchBox from 'components/SwitchBox';
import MenuItem from './MenuItem';
import FreeBets from 'components/FreeBets';
import GameLimits from 'components/GameLimits';
import { Rules } from 'components/Rules';
import { MyBetsHistory } from 'features/History';

import './Menu.scss';

const b = block('menu');

const Menu = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const { icon_id, nick, userID } = useSelector(state => state.auth);
    const { locale } = useSelector(state => state.locale);
    const { isSound, isMusic, isAnimations } = useSelector(state => state.settings);
    const { isRulesOpen } = useSelector(state => state.game);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenFreeBets, setIsOpenFreeBets] = useState(false);
    const [isOpenMyBetsHistory, setIsOpenMyBetsHistory] = useState(false);
    const [isOpenGameLimits, setIsOpenGameLimits] = useState(false);

    const switchSound = useCallback(value => {
        dispatch(actions.setSound(value));
    }, [dispatch]);

    const switchMusic = useCallback(value => {
        dispatch(actions.setMusic(value));
    }, [dispatch]);

    const switchAnimation = useCallback(value => {
        dispatch(actions.setAnimations(value));
    }, [dispatch]);

    const onRulesItemClick = useCallback(() => {
        dispatch(gameActions.setRulesOpen(true));
        onClose();
    }, [dispatch]);

    const onOpenFreeBetClick = useCallback(() => {
        setIsOpenFreeBets(true);
        onClose();
    }, []);

    const onCloseFreeBetClick = useCallback(() => {
        setIsOpenFreeBets(false);
    }, []);

    const openMyBetsHistory = useCallback(() => {
        setIsOpenMyBetsHistory(true);
        onClose();
    }, []);

    const closeMyBetsHistory = useCallback(() => {
        setIsOpenMyBetsHistory(false);
    }, []);

    const openGameLimits = useCallback(() => {
        setIsOpenGameLimits(true);
        onClose();
    }, []);

    const closeGameLimits = useCallback(() => {
        setIsOpenGameLimits(false);
    }, []);

    const onChangeIconClick = useCallback(() => {
        setIsOpenEdit(true);
        onClose();
    }, []);

    return <>
        {open && (
            <div className={b()} onClick={onClose}>
                <div className={b('container')} onClick={e => e.stopPropagation()}>
                    <div className={b('header')}>
                        <div className={b('user-info')}>
                            <img src={getIconById(icon_id)} className={b('icon')} loading="lazy" alt="icon" />
                            {nick ? nick : userID}
                        </div>
                        <div className={b('change-icon')} onClick={onChangeIconClick}>
                            <SVGInline svg={changeIcon} className={b('person-icon').toString()} />
                            <div className={b('text')}>
                                <div>{locale.change}</div>
                                <div>{locale.avatar}</div>
                            </div>
                        </div>
                    </div>
                    <div className={b('content')}>
                        <div className={b('settings-items')}>
                            <MenuItem icon={soundIcon} text={locale.sound} actions={<SwitchBox value={isSound} changeValue={value => switchSound(value)} />} />
                            <MenuItem isNeedBorder={true} icon={musicIcon} text={locale.music} actions={<SwitchBox value={isMusic} changeValue={value => switchMusic(value)} />} />
                            <MenuItem isNeedBorder={true} icon={animationIcon} text={locale.animation} actions={<SwitchBox value={isAnimations} changeValue={value => switchAnimation(value)} />} />
                        </div>
                        <div className={b('items-container')}>
                            <MenuItem isPointer={true} icon={star} text={locale.freeBets} callBack={onOpenFreeBetClick} />
                            <MenuItem isPointer={true} isNeedBorder={true} icon={rules} text={locale.gameRules} callBack={onRulesItemClick} />
                            <MenuItem isPointer={true} isNeedBorder={true} icon={history} text={locale.myBetHistory} callBack={openMyBetsHistory} />
                            <MenuItem isPointer={true} isNeedBorder={true} icon={limits} text={locale.gameLimits} callBack={openGameLimits} />
                        </div>
                    </div>
                    <div className={b('footer')}>
                        <HomeButton />
                    </div>
                </div>
            </div>
        )}
        {isOpenEdit && <EditUser setIsOpen={setIsOpenEdit} isOpen={isOpenEdit} />}
        {isRulesOpen && <Rules />}
        {isOpenFreeBets && <FreeBets onClose={onCloseFreeBetClick} />}
        {isOpenMyBetsHistory && <MyBetsHistory onClose={closeMyBetsHistory} />}
        {isOpenGameLimits && <GameLimits onClose={closeGameLimits} />}
    </>
};

export default Menu;