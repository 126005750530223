import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/Game';

import SwitchBox from 'components/SwitchBox';
import Input from 'components/Input';
import Button from 'components/Button';
import AutoBetItem from 'components/AutoBetItem';
import ChangeAmount from 'components/ChangeAmount';

import './BasketItem.scss';

const b = block('basket-item');

const termsArray = [1, 2, 5, 10];

const BasketItem = ({
    isProcess,
    periodType,
    index,
    button,
}) => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const currency = useSelector(state => state.auth.currency);
    const balance = useSelector(state => state.auth.balance);
    const tempCoef = useSelector(state => state.game.coef);
    const limits = useSelector(state => state.game.limits);
    const roundID = useSelector(state => state.game.roundID);
    const { winAmount } = useSelector(state => state.game);
    const [isAutoMode, setIsAutoMode] = useState(false);
    const [isPlaced, setPlaced] = useState(false);
    const tempBet = useSelector(state => state.game.cashedBets)?.find(t => t.bet_id === isPlaced);
    const [isAutoCashout, setIsAutoCashout] = useState(false);
    const [amount, setAmount] = useState(limits.min);
    const [isBlock, setBlock] = useState(false);
    const [coef, setCoef] = useState(5);
    const [isNextBet, setNextBet] = useState(false);
    const [isOpenAutoBet, setIsOpenAutoBet] = useState(false);
    const [isAutoBet, setIsAutoBet] = useState(false);
    const [autoBetCount, setAutoBetCount] = useState(null);
    const [autoBetApplyOptions, setAutoBetApplyOptions] = useState(null);


    const [autoBetOptions, setAutoBetOptions] = useState({
        isDecreaseStop: false,
        decreaseStopAmount: 0,
        isIncreaseStop: false,
        increaseStopAmount: 0,
        isWinExceedsStop: false,
        winExceedsStopAmount: 0,
    });

    const openAutoBetItem = useCallback(() => {
        setIsOpenAutoBet(true);
    }, []);

    const closeAutoBetItem = useCallback(() => {
        setIsOpenAutoBet(false);
    }, []);

    const turnOffAutoBet = useCallback(() => {
        resetAutoBetOptions();
        setAutoBetCount(null);
        setIsAutoBet(false);
    }, []);

    const turnOnAutoBet = useCallback(() => {
        setIsAutoBet(true);
    }, []);

    const resetAutoBetOptions = useCallback(() => {
        setAutoBetOptions({
            isDecreaseStop: false,
            decreaseStopAmount: 0,
            isIncreaseStop: false,
            increaseStopAmount: 0,
            isWinExceedsStop: false,
            winExceedsStopAmount: 0,
        });
    }, []);

    useEffect(() => {
        if (!isAutoBet || !autoBetApplyOptions || roundID === autoBetApplyOptions.roundID) {
            return;
        }

        const decreaseCondition = autoBetOptions.isDecreaseStop && autoBetOptions.decreaseStopAmount > (balance - autoBetApplyOptions.balance);
        const increaseCondition = autoBetOptions.isIncreaseStop && autoBetOptions.increaseStopAmount < (balance - autoBetApplyOptions.balance);
        const winExceedCondition = autoBetOptions.isWinExceedsStop && autoBetOptions.winExceedsStopAmount < winAmount;

        if (autoBetCount === 0 || (winExceedCondition && autoBetOptions.isWinExceedsStop) || (decreaseCondition && autoBetOptions.isDecreaseStop) || (increaseCondition && autoBetOptions.isIncreaseStop)) {
            setIsAutoBet(false);
            setAutoBetApplyOptions(null);
            resetAutoBetOptions();
        }
    }, [
        autoBetCount,
        autoBetOptions,
        balance,
        winAmount,
        autoBetApplyOptions,
        isAutoBet,
    ]);


    // Place bet function
    const placeBet = useCallback(() => {
        dispatch(actions.placeBet(+amount, +coef, isAutoCashout, setPlaced, isAutoBet, index));
    },
        [amount, coef, dispatch, isAutoBet, isAutoCashout, index]);

    // Auto bet
    useEffect(() => {
        if (periodType === 2) setPlaced(false);
        if (periodType === 2 && isAutoBet) {
            placeBet();
            setAutoBetCount(prev => prev - 1);
        }
        if (periodType === 2 && isNextBet) {
            setNextBet(false);
            placeBet();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodType, isAutoBet, isNextBet])

    // Cashout money function
    const cashoutBet = useCallback(() => {
        dispatch(actions.cashoutBet(isPlaced, setPlaced))
    }, [dispatch, isPlaced]);

    const cancelBet = useCallback(() => {
        if (isNextBet && periodType === 1) {
            setNextBet(false);
        } else {
            // dispatch(actions.cancelBet(isPlaced, setPlaced))
        }
    }, [isNextBet, periodType])

    // Reset bet
    useEffect(() => {
        if (tempBet && isPlaced) {
            setPlaced(false)
        }
    }, [isPlaced, tempBet])

    // Set block
    useEffect(() => {
        if (isPlaced || isProcess || (isNextBet && periodType === 2)) {
            setBlock(true)
        } else {
            setBlock(false)
        }
    }, [isPlaced, isProcess, isNextBet, periodType])

    // Set limits
    useEffect(() => setAmount(limits.min), [limits.min])

    // For validate after blur coef
    const validateBlurCoef = value => {
        if (isAutoCashout) {
            if (+value > 1.1) {
                setCoef(+(+value).toFixed(2))
            } else {
                setCoef(1.1)
            }
        }
    }

    // For validate amount
    const validateChangeAmount = useCallback((value, onChange) => {
        if (!isBlock) {
            if (value > limits.max) onChange(+limits.max.toFixed(1))
            else if (value < limits.min) onChange(+limits.min.toFixed(1))
            else if (coef * value > limits.win) onChange(+(limits.win / coef).toFixed(1))
            else if (+(+value).toFixed(1) > balance) {
                if (balance < limits.min) {
                    onChange(+limits.min.toFixed(1))
                } else {
                    onChange(Math.floor(balance * 10, 1) / 10)
                }
            }
            else onChange(+(+value).toFixed(1));
        }
    }, [balance, coef, isBlock, limits.max, limits.min, limits.win])

    // For validate input Amount
    const validateTempAmount = useCallback((value, onChange) => {
        if (!isBlock) {
            if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
                value.toString().split('').filter(t => t === '.').length < 2) {
                if (value.toString().split('')[0] === '.') {
                    onChange(value.substring(1, value.length))
                } else onChange(value)
            }
        }
    }, [isBlock])


    // For validate input coef
    const validateChangeCoef = value => {
        if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
            value.toString().split('').filter(t => t === '.').length < 2 && isAutoCashout) {
            if (value < 1.1 && value !== '' && +value !== 1) setCoef(1.1)
            else if (amount * value > limits.win) setCoef(limits.win / amount)
            else setCoef(value);
        }
    }

    const buttonState = useMemo(() => {
        if (isPlaced) {
            return periodType === 1 ? 'cashout' : 'cancel-bet';
        } else {
            if (periodType !== 1) {
                return 'place-bet';
            } else if (isNextBet) {
                return 'cancel-pre-bet'
            } else {
                return 'place-next-bet'
            }
        }
    }, [isPlaced, periodType, isNextBet]);

    const tempButtonOptionsMap = useMemo(() => ({
        'cashout': {
            text: (
                <div className={b('text-button-container')}>
                    <div>{locale.cashout}</div>
                    <div className={b('button-title')}>{`${(tempCoef * amount).toFixed(2)} ${currency}`}</div>
                </div>
            ),
            color: 'yellow',
            callBack: cashoutBet,
        },
        'cancel-bet': {
            text: <div className={b('button-title')}>{locale.cancelBet}</div>,
            color: 'red',
            callBack: cancelBet,
            disabled: true,
        },
        'cancel-pre-bet': {
            text: <div className={b('button-title')}>{locale.cancelBet}</div>,
            color: 'active-red',
            callBack: cancelBet,
        },
        'place-bet': {
            text: (
                <div className={b('text-button-container')}>
                    <div>{locale.bet}</div>
                    <div className={b('button-title')}>{periodType === 2 ? `${amount.toFixed(2)} ` : `${(tempCoef * amount).toFixed(2)} `}<div className={b('button-curency')}>{currency}</div></div>
                </div>),
            color: 'green',
            callBack: placeBet,
        },
        'place-next-bet': {
            text: (
                <div className={b('text-button-container')}>
                    <div>{locale.bet}</div>
                    <div className={b('button-title')}>{periodType === 2 ? `${amount.toFixed(2)} ` : `${(tempCoef * amount).toFixed(2)} `}<div className={b('button-curency')}>{currency}</div></div>
                </div>
            ),
            color: 'green',
            callBack: () => setNextBet(true),
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [locale, tempCoef, amount, currency]);

    const tempButtonOptions = tempButtonOptionsMap[buttonState];

    const onKeyPressHandler = useCallback(e => {
        if (e.keyCode === 38) {
            setCoef(prev => prev + 0.1);
        }
        if (e.keyCode === 40) {
            setCoef(prev => {
                const newCoef = prev - 0.1;
                return newCoef < 1.1 ? 1.1 : newCoef;
            });
        }
    }, []);

    return <>
        <div className={b({ border: buttonState })}>
            <div className={b('control-mode-block')}>
                <div className={b('mode', { isActiveMode: !isAutoMode })} onClick={isAutoBet ? f => f : () => setIsAutoMode(false)}>{locale.bet}</div>
                <div className={b('mode', { isActiveMode: isAutoMode })} onClick={() => setIsAutoMode(true)}>{locale.auto}</div>
                {button}
            </div>
            <div className={b('container', { isAutoMode })}>
                <div className={b('bet-amount-container')}>
                    <ChangeAmount
                        value={amount}
                        onPlusClick={() => validateChangeAmount(amount + 0.1, setAmount)}
                        onMinusClick={() => validateChangeAmount(amount - 0.1, setAmount)}
                        onClick={() => validateChangeAmount(amount, setAmount)}
                        onChange={e => validateTempAmount(e.currentTarget.value, setAmount)}
                    />
                    <div className={b('terms-container')}>
                        <div className={b('term')} onClick={() => validateChangeAmount(amount + termsArray[0], setAmount)}>{termsArray[0]}</div>
                        <div className={b('term')} onClick={() => validateChangeAmount(amount + termsArray[1], setAmount)}>{termsArray[1]}</div>
                        <div className={b('term')} onClick={() => validateChangeAmount(amount + termsArray[2], setAmount)}>{termsArray[2]}</div>
                        <div className={b('term')} onClick={() => validateChangeAmount(amount + termsArray[3], setAmount)}>{termsArray[3]}</div>
                    </div>
                </div>
                <div className={b('bet-button')}>
                    {buttonState === 'cancel-pre-bet' && locale.waitingNextRound}
                    <Button
                        text={tempButtonOptions.text}
                        size='container'
                        isUppercase
                        isDisabled={isProcess || tempButtonOptions.disabled}
                        color={tempButtonOptions.color}
                        callBack={() => {
                            tempButtonOptions.callBack();
                            dispatch(actions.buttonClick());
                        }}
                    />
                </div>
            </div>
            {isAutoMode && <div className={b('auto-mode-container')}>
                <Button
                    text={isAutoBet ? `${locale.stop}(${autoBetCount})` : locale.autoPlay}
                    isUppercase
                    color={isAutoBet ? 'redStop' : 'blue'}
                    callBack={isAutoBet ? turnOffAutoBet : openAutoBetItem}
                />
                <div className={b('auto-cashout')}>
                    <div className={b('auto-cashout-swither')}>
                        {locale.autoCashOut}
                        <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setIsAutoCashout(value)} />
                    </div>
                    <div className={b('auto-cashout-amount')}>
                        <Input
                            value={coef.toFixed(2)}
                            onChange={e => validateChangeCoef(e.currentTarget.value)}
                            onKeyDown={e => onKeyPressHandler(e)}
                            onBlur={() => validateBlurCoef(coef)}
                            unBorder
                            leftText
                            isBlure={!isAutoCashout}
                        />
                        <div className={b('text', { isBlure: !isAutoCashout })}>x</div>
                    </div>
                </div>
            </div>}
        </div>
        {isOpenAutoBet && <AutoBetItem
            validateChangeAmount={validateChangeAmount}
            validateTempAmount={validateTempAmount}
            onClose={closeAutoBetItem}
            turnOnAutoBet={turnOnAutoBet}
            setAutoBetCount={setAutoBetCount}
            resetAutoBetOptions={resetAutoBetOptions}
            setAutoBetApplyOptions={setAutoBetApplyOptions}
            autoBetOptions={autoBetOptions}
            setAutoBetOptions={setAutoBetOptions}
        />}
    </>
};

export default BasketItem;