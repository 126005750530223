import React, { useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { getIconById } from 'services/img/icons';
import { roundingFunc } from 'services/helpers/roundingFunc';
import { actions } from '../';

import './TopWins.scss';

const TopWins = () => {
  const dispatch = useDispatch();
  const b = block('top-wins');
  const locale = useSelector(state => state.locale.locale);
  const currency = useSelector(state => state.auth.currency);
  const { topWins } = useSelector(state => state.history);

  useEffect(() => {
    dispatch(actions.getTopWins());
  }, [dispatch])

  const topWinsList = useMemo(() => (
    topWins.map((t, i) => (
      <div key={`${t.value}${t.round_id}${i}`} className={b('item')}>
        <div className={b('main-block')}>
          <div className={b('user-info')}>
            <img src={getIconById(t.icon_id)} className={b('icon')} loading='lazy' alt='icon' />
            <div className={b('user-name')}>{t.nick || t.user_id}</div>
          </div>
          <div className={b('row')}>
            {`${locale.bet}, ${currency}:`}
            <div className={b('text')}>{roundingFunc(t.bet_amount)}</div>
          </div>
          <div className={b('row')}>
            {`${locale.cashedOut}:`}
            <div className={b('coef')}>{`${roundingFunc(+t.coefficient)}x`}</div>
          </div>
          <div className={b('row')}>
            {`${locale.win}, ${currency}:`}
            <div className={b('text')}>{roundingFunc(t.win_amount)}</div>
          </div>
        </div>
      </div>
    ))
  ), [b, topWins, currency, locale]);



  return <section className={b()}>
    <div className={b('items')}>
      {topWinsList}
    </div>
  </section>
}

export default TopWins;