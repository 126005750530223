import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { getIconById } from 'services/img/icons';

import user from 'services/img/user.svg';

import './Bets.scss';

const Bets = () => {
  const b = block('bets');
  const locale = useSelector(state => state.locale.locale);
  const { currency } = useSelector(state => state.auth);
  const bets = useSelector(state => state.history.bets) || [];

  const resultList = useMemo(() => (
    bets.map((t, i) => (
      <div key={`${t.user_id}${i}`} className={b('item', { winBet: t.isWin })}>
        <div className={b('column-icon')}>
          <img src={getIconById(t.icon_id)} className={b('icon')} loading='lazy' alt='icon' />
          {t.nick || t.user_id}
        </div>
        <div className={b('amount')}>
          {t.bet_amount}
        </div>
        <div className={b('coeff')}>
          {t.coefficient ? `${t.coefficient}x` : '-'}
        </div>
        <div className={b('win-amount')}>
          {t.win_amount ? `${t.win_amount}${t.currency}` : '-'}
        </div>
      </div>
    ))
  ), [b, bets])

  return <section className={b()}>
    <div className={b('total-bets')}>{locale.totalBets}</div>
    <div className={b('bets-amount')}>
      <SVGInline svg={user} className={b('user-icon').toString()} />
      {bets?.lenght || 0}
    </div>
    <div className={b('header')}>
      <div className={b('column', { user: true })}>
        {locale.user}
      </div>
      <div className={b('column', { bet: true })}>
        {`${locale.bet}, ${currency}`}
      </div>
      <div className={b('column')}>
        X
      </div>
      <div className={b('column', { win: true })}>
        {locale.cashOut}
      </div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default Bets;
