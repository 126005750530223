import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import logo from 'services/img/logoText.svg';

import './Footer.scss';

const b = block('footer');

const Footer = () => {
    const { locale } = useSelector(state => state.locale);

    return <div className={b()}>
        {locale.poweredBy}
        <SVGInline svg={logo} className={b('icon').toString()} />
    </div>
};

export default Footer;